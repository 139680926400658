import {useTranslation} from 'i18n';
import {useContext} from 'react';

import {timestampToLocale} from '~utils/localeUtil';
import {getRoute, Route} from '~utils/routeUtil';
import {selectCurrentImage} from '~redux/reducers/imageReducer';
import {useAppSelector} from '~redux/index';

import {useTeachSessionPageTitle} from '~components/images/utils/image-mode-mixins';
import {ModelContext} from 'src/contexts/ModelContext';

export type Breadcrumb = {
  name: string;
  path?: string;
};

/**
 * This hook maintains the breadcrumbs and titles for all pages in the application. When adding new pages, make sure to add
 * the breadcrumbs here. Breadcrumbs are not maintained on a page level, because some page titles need to be dynamic. Having
 * them in one place makes it easier to maintain them.
 *
 * @param currentPath the current path as returned by router.pathname
 * @returns the breadcrumbs for the current path.
 */
export function useBreadcrumbs(currentPath: string): Breadcrumb[] {
  const {t} = useTranslation(['mainMenu', 'models', 'common']);
  const {currentModel} = useContext(ModelContext);
  const currentImage = useAppSelector(selectCurrentImage);

  // Static breadcrumbs (to keep things DRY)
  const modelsBreadcrumb = {
    name: t('models'),
    path: getRoute(Route.models, {keepModelIdQueryParam: false}),
  };

  // Dynamic breadcrumbs that are updated based on application state
  const modelSamplesBreadcrumb = {
    name: currentModel?.name ? t('samplesFor', {ns: 'models', name: currentModel?.name}) : '',
    path: getRoute(Route.modelSamples),
  };
  const modelAddSamplesBreadcrumb = {
    name: currentModel?.name ? t('addSamplesTo', {ns: 'models', name: currentModel?.name}) : '',
    path: getRoute(Route.modelAddSamples),
  };
  const imageBreadcrumb = {name: currentImage ? timestampToLocale(currentImage.timestamp) : ''};
  const teachSessionBreadcrumb = {name: useTeachSessionPageTitle({fallback: t('session')})};

  // This should be kept in sync with the routes in routeUtil.ts
  switch (currentPath) {
    case Route.index:
    case Route.login:
    case Route.noProjects:
      // No page titles/breadcrumbs here
      return [];
    case Route.archive:
      return [{name: t('archive')}];
    case Route.archiveImage:
      return [{name: t('archive'), path: getRoute(Route.archive, {keepModelIdQueryParam: false})}, imageBreadcrumb];
    case Route.simsearch:
      return [
        {
          name: t('similaritySearch.title', {
            ns: 'models',
          }),
        },
      ];
    case Route.simsearchImage:
      return [
        {
          name: t('similaritySearch.title', {
            ns: 'models',
          }),
          path: getRoute(Route.simsearch),
        },
        imageBreadcrumb,
      ];
    case Route.heatmap:
      return [{name: t('heatmap')}];
    case Route.monitor:
      return [{name: t('monitor')}];
    case Route.modelAddSamples:
      return [modelsBreadcrumb, modelAddSamplesBreadcrumb];
    case Route.modelAddSamplesImage:
      return [modelsBreadcrumb, modelAddSamplesBreadcrumb, imageBreadcrumb];
    case Route.modelStatistics:
    case Route.modelLabelDistribution:
    case Route.modelLabelNoise:
      return [modelsBreadcrumb, modelSamplesBreadcrumb, {name: t('statistics')}];
    case Route.modelNew:
      return [modelsBreadcrumb, {name: t('newModel')}];
    case Route.models:
      return [{name: t('models')}];
    case Route.modelSamples:
      return [modelsBreadcrumb, modelSamplesBreadcrumb];
    case Route.modelSamplesImage:
      return [modelsBreadcrumb, modelSamplesBreadcrumb, imageBreadcrumb];
    case Route.modelSettings:
      return [modelsBreadcrumb, modelSamplesBreadcrumb, {name: t('settings')}];
    case Route.modelTraining:
      return [modelsBreadcrumb, modelSamplesBreadcrumb, {name: t('training')}];
    case Route.modelFinetune:
    case Route.modelFinetuneImage:
      return [modelsBreadcrumb, modelSamplesBreadcrumb, {name: t('modelFinetuneTitle', {ns: 'models'})}];
    case Route.projects:
      return [{name: t('projects')}];
    case Route.settingsAutomaticReports:
    case Route.settingsDevices:
    case Route.settingsNotifications:
    case Route.settingsShifts:
    case Route.settingsUsers:
      return [{name: t('settings')}];
    case Route.settingsChangelog:
      return [{name: t('settings'), path: getRoute(Route.settingsShifts)}, {name: t('changelog')}];
    case Route.tasks:
      return [{name: t('tasks')}];
    case Route.tasksLiveSession:
    case Route.tasksSession:
      return [{name: t('tasks'), path: getRoute(Route.tasks, {keepModelIdQueryParam: false})}, teachSessionBreadcrumb];
    case Route.deploy:
      return [{name: t('deploy')}];
    case Route.notFound:
      return [{name: t('errorPageNotFound', {ns: 'common'})}];
    case Route.apiUnavailable:
      return [{name: t('errorServiceUnavailable', {ns: 'common'})}];
    default:
      // If this is visible, a route was added or changed without updating this switch statement
      console.error(`No breadcrumbs defined for ${currentPath}!`);
      return [];
  }
}
