import {Box, BreadcrumbsProps, Breadcrumbs as MUIBreadcrumbs} from '@mui/material';
import React, {ReactNode} from 'react';

import {styled} from '~constants/theme';

import {SlashIcon} from 'src/icons';

const StyledBreadcrumbs = styled(MUIBreadcrumbs, {shouldForwardProp: (prop) => prop !== 'cursor'})<
  BreadcrumbsProps & {cursor?: string}
>(({theme, cursor}) => ({
  gridArea: 'breadcrumbs',
  flexWrap: 'wrap',
  '& li, & li a': {
    cursor,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const StyledBreadcrumbsBox = styled(Box)({
  display: 'grid',
  minHeight: 52,
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: 'auto 1fr',
  gridTemplateAreas: `
        'breadcrumbs title-actions'
        'title title-actions'
        `,
  columnGap: 1,
});

export const BreadcrumbLayout = ({children, cursor}: {children: ReactNode; cursor?: string}) => {
  return (
    <StyledBreadcrumbs cursor={cursor} separator={<SlashIcon />}>
      {children}
    </StyledBreadcrumbs>
  );
};
